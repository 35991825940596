// src/router/index.js
import Vue from 'vue';
import Router from 'vue-router';
import Home from '../views/Home.vue';
import Description from '../views/Description.vue';
import Solutions from '../views/Solutions.vue';
import Company from '../views/Company.vue';
import Pricing from '../views/Pricing.vue';
import JoinNow from '../views/JoinNowForm.vue';

Vue.use(Router);

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'Home',
      component: Home
    },
    {
      path: '/description',
      name: 'Description',
      component: Description
    },
    {
      path: '/solutions',
      name: 'Solutions',
      component: Solutions
    },
    {
      path: '/company',
      name: 'Company',
      component: Company
    },
    {
      path: '/pricing',
      name: 'Pricing',
      component: Pricing
    },

    {
      path: '/joinnow',
      name: 'JoinNow',
      component: JoinNow
    },
    // 可以在此处添加更多页面的路由配置
  ]
});
