<template>
  <div>
    <div class="product-intro">
      <h2 class="section-title">{{ $t('description.sectionTitles.productIntro') }}</h2>
      <div class="features">
        <div class="feature">
          <h3 v-html="$t('description.features.feature1.title')"></h3>
          <div class="feature-content with-border">
            <p>{{ $t('description.features.feature1.content') }}</p>
          </div>
        </div>
        <div class="feature">
          <h3 class="highlight" v-html="$t('description.features.feature2.title')"></h3>
          <div class="feature-content with-border">
            <p>{{ $t('description.features.feature2.content') }}</p>
          </div>
        </div>
        <div class="feature">
          <h3>{{ $t('description.features.feature3.title') }}</h3>
          <div class="feature-content with-border">
            <p>{{ $t('description.features.feature3.content') }}</p>
          </div>
        </div>
        <div class="feature">
          <h3>{{ $t('description.features.feature4.title') }}</h3>
          <div class="feature-content">
            <p>{{ $t('description.features.feature4.content') }}</p>
          </div>
        </div>
      </div>
    </div>

    <div class="tech-advantage">
      <h2 class="section-title">{{ $t('description.sectionTitles.techAdvantage') }}</h2>
      <div class="advantages">
        <!-- 自我学习与改进在左侧，图片在右侧 -->
        <div class="advantage advantage-top">
          <div class="advantage-content">
            <h3>{{ $t('description.advantages.advantage1.title') }}</h3>
            <p v-html="$t('description.advantages.advantage1.content')"></p>
          </div>
          <div class="advantage-image">
            <img :alt="$t('description.images.selfLearningAlt')" src="../assets/self-learning.png">
          </div>
        </div>

        <!-- 系统集成在下方 -->
        <div class="advantage advantage-bottom">
          <div class="advantage-image">
            <img :alt="$t('description.images.systemIntegrationAlt')" src="../assets/system-integration.png">
          </div>
          <div class="advantage-content">
            <h3>{{ $t('description.advantages.advantage2.title') }}</h3>
            <p>{{ $t('description.advantages.advantage2.content') }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  name: "Description",
};
</script>

<style scoped>
.product-intro {
  text-align: center;
  margin: 0 auto;
  padding: 60px 20px;
  max-width: 1200px;
}

.section-title {
  font-size: 40px;
  font-weight: bold;
  margin-bottom: 80px;
}

.features {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.feature {
  flex: 1;
  text-align: center;
  padding: 20px;
}

.feature h3 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 80px;
}
/* 添加竖线样式 */
.feature-content {
  padding-right: 20px;
  text-align: left;
}

.feature-content p {
  font-size: 18px;
  color: #666;
  max-width: 100%; /* 最大宽度为 100% 以适应容器 */
  margin: 0 auto;
}



.with-border {
  border-right: 1px solid #ccc;
}


.tech-advantage {
  text-align: center;
  margin: 0 auto;
  padding: 60px 20px;
  max-width: 1200px;
}

.advantages {
  display: flex;
  flex-direction: column;
  gap: 40px;
  align-items: center;
}

.advantage {
  display: flex;
  align-items: center;
  width: 80%;
  text-align: left;
}

/* 上半区块（自我学习与改进）的样式 */
.advantage-top .advantage-content {
  margin-right: 40px; /* 调整文本和图片之间的间距 */
}

.advantage-top .advantage-image {
  margin-left: 40px; /* 调整图片和文本之间的间距 */
}

/* 下半区块（系统集成）的样式 */
.advantage-bottom .advantage-content {
  margin-left: 40px; /* 调整文本和图片之间的间距 */
}

.advantage-bottom .advantage-image {
  margin-right: 40px; /* 调整图片和文本之间的间距 */
}

.advantage-content h3 {
  font-size: 30px;
  font-weight: bold;
  margin: 10px 0;
}

.advantage-content p {
  font-size: 18px;
  color: #666;
  line-height: 1.5;
}

</style>
