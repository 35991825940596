<template>
  <div class="homepage">
    <!-- Hero Section -->
    <section :style="heroBackgroundStyle" class="hero-section">

    </section>

    <!-- Main Page Container -->
    <div class="page-container">
      <!-- Top Navigation Button -->
      <div>
        <el-button class="custom-button" size="large" type="primary" @click="goToTryNow">{{ $t('home.button.tryNow') }}</el-button>
      </div>

      <!-- Section Title -->
      <div class="section-title">
        <h2>{{ $t('home.sectionTitles.productIntro') }}</h2>
      </div>

      <div class="homepage">
        <!-- Feature List -->
        <div class="feature-list">
          <span v-for="(feature, index) in features" :key="index" :class="{ active: activeIndex === index }"
                @click="setSlide(index)">
            {{ feature }}
          </span>
        </div>

        <!-- Carousel Section -->
        <div class="carousel-wrapper">
          <button class="carousel-btn left-btn" @click="prevSlide">‹</button>
          <div class="carousel-container">
            <div class="carousel">
              <div class="carousel-slide">
                <img :alt="slides[currentSlide].alt" :src="slides[currentSlide].src"/>
              </div>
            </div>
          </div>
          <button class="carousel-btn right-btn" @click="nextSlide">›</button>
        </div>
      </div>

      <!-- 合作企业 Section -->
      <div class="partners-section">
        <h2>{{ $t('home.sectionTitles.partners') }}</h2>
        <div class="partners-list-wrapper">
          <div class="partners-list">
            <span v-for="partner in partners" :key="partner" style="margin: 0px 50px">{{ partner }}</span>
          </div>
        </div>
      </div>


      <!-- 客户评价和成功案例 Section -->
      <div class="testimonials-section">
        <h2 style="margin-bottom: 40px">{{ $t('home.sectionTitles.testimonials') }}</h2>
        <div class="testimonial-image">
          <img :alt="$t('home.sectionTitles.testimonials')" src="../assets/client.png"/>
        </div>
      </div>

      <!-- 支持多平台 Section -->
      <div class="container">
        <!-- Title Section -->
        <div class="platform-title">
          <span>{{ $t('home.sectionTitles.multiPlatform') }}</span>
        </div>

        <!-- Logo Section with Divider Lines -->
        <div class="title-section">
          <hr class="line"/>
          <div class="logo-section">
            <img alt="ServeBot.AI Logo" class="logo" src="../assets/logo/logo-black.png"/>
          </div>
          <hr class="line"/>
        </div>

        <!-- Platforms Section -->
        <div class="platforms-section">
          <div v-for="(platform, index) in platforms" :key="index" class="platform">
            <img :alt="platform.name" :src="platform.icon" class="platform-icon"/>
            <p class="platform-name">{{ platform.name }}</p>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>


<script>


export default {
  name: "Home",
  data() {
    return {
      features: this.$t('home.features'),
      slides: [
        {src: require("../assets/slides1.png"), alt: "Slide 1"},
        {src: require("../assets/slides2.png"), alt: "Slide 2"},
        {src: require("../assets/slides3.png"), alt: "Slide 3"},
        {src: require("../assets/slides4.png"), alt: "Slide 4"},
      ],
      partners: ["M&K コーポレーション", "株式会社フューチャーリーディング", "株式会社 NewTide", "株式会社ウィラス", "東京 JM 株式会社"],
      platforms: [
        {name: "WhatsApp", icon: require("../assets/icon/whatsapp.png")},
        {name: "WeCom", icon: require("../assets/icon/wecom.png")},
        {name: "Line", icon: require("../assets/icon/line.png")},
        {name: "Airbnb", icon: require("../assets/icon/airbnb.png")},
        {name: "Booking", icon: require("../assets/icon/booking.png")},
        {name: "WeChat", icon: require("../assets/icon/wechat.png")},
        {name: "Sina Mail", icon: require("../assets/icon/sina.png")},
        {name: "NetEase", icon: require("../assets/icon/netease.png")},
      ],
      currentSlide: 0,
      activeIndex: 0,
      heroBackgroundStyle: {
        backgroundImage: `url(${require(`@/assets/${this.$t('home.heroSection.backgroundImage')}`)})`
      }
    };
  },
  watch: {
    '$i18n.locale'() {
      this.features = this.$t('home.features');

      // 监听语言切换并更新背景图片
      const relativePath = this.$t('home.heroSection.backgroundImage');
      const imagePath = require(`@/assets/${relativePath}`);
      this.heroBackgroundStyle = {backgroundImage: `url(${imagePath})`};

    }
  },
  methods: {
    goToTryNow() {
      this.$router.push({ path: '/joinnow' }); // 替换为您的目标路径
    },
    nextSlide() {
      this.currentSlide = (this.currentSlide + 1) % this.slides.length;
      this.updateActiveIndex();
    },
    prevSlide() {
      this.currentSlide = (this.currentSlide - 1 + this.slides.length) % this.slides.length;
      this.updateActiveIndex();
    },
    setSlide(index) {
      // 设置 currentSlide 和 activeIndex 为选项卡的索引
      this.currentSlide = index;
      this.activeIndex = index;
    },
    updateActiveIndex() {
      // 更新选项卡的活动索引
      this.activeIndex = this.currentSlide;
    },
  },
};
</script>


<style scoped>
.homepage {
  font-family: "Microsoft YaHei", "Helvetica Neue", Arial, sans-serif;
  font-size: 18px;
  color: #fff;
  background-color: #000;
  margin-bottom: 80px;
}

.hero-section {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80vh;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

h2 {
  font-weight: bold;
  font-size: 40px;
  background: linear-gradient(180deg, #e8fdf2, #ebeeff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 80px;
}

.page-container {
  text-align: center;
}

.section-title {
  font-size: 34px;
  font-weight: bold;
  margin: 30px 0;
}

.platform-title {
  font-size: 40px;
  font-weight: bold;
  color: #000012;
  margin-bottom: 110px;
}

.custom-button {
  background-color: #ffffff !important;
  color: #000000 !important;
  border: none !important;
  border-radius: 20px !important;
  padding: 15px 40px;
  width: 400px;
  height: 80px;
  font-size: 25px;
  margin-bottom: 80px;
}

.custom-button:hover {
  background-color: #f2f2f2 !important;
}

.feature-list {
  display: flex;
  justify-content: center;
  gap: 40px;
  //padding-bottom: 60px;
  height: 60px;
}

.feature-list span {
  font-size: 22px;
  color: #ffffff;
  transition: font-size 0.3s ease;
}

.feature-list span.active {
  font-size: 26px;
  font-weight: bold;
}

.carousel-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin: 60px 0;
}

.carousel-container {
  width: 800px;
  height: 600px;
  position: relative;
}

.carousel-slide img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.carousel-btn {
  background-color: rgba(0, 0, 0, 0.5);
  color: #ffffff;
  border: none;
  font-size: 24px;
  cursor: pointer;
  padding: 10px;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease;
}

.carousel-btn:hover {
  background-color: rgba(0, 0, 0, 0.7);
}

.left-btn {
  margin-right: 250px;
}

.right-btn {
  margin-left: 250px;
}

.partners-section {
  text-align: center;
  margin: 50px 0;
}

.partners-list-wrapper {
  overflow: hidden;
  background-color: #004be4;
  padding: 100px 15px;
  display: flex;
  justify-content: center;
  margin-bottom: 80px;
}

.partners-list {
  display: inline-flex;
  gap: 20px;
  animation: scroll 20s linear infinite;
  white-space: nowrap;
  font-size: 24px;
  font-weight: bold;
  color: #ffffff;
}

@keyframes scroll {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

.testimonials-section {
  text-align: center;
  margin: 50px 0;
}

.testimonial-image img {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}



.container {
  text-align: center;
  padding: 90px 20px 40px 20px;
  background-color: #fff;
}

.title-section {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 80px;
}

.line {
  width: 450px;
  background-color: transparent;
  border-top: 2px dashed #666;
  margin: 0 60px;
}

.logo {
  height: 72px;
  width: auto;
}

.platforms-section {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 20px;
}

.platform {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 120px;
}

.platform-icon {
  width: 100px;
  height: 100px;
  margin-bottom: 5px;
}

.platform-name {
  font-size: 14px;
  color: #666;
}



</style>
