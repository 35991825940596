<template>
  <div>
    <!-- Call-to-Action Section -->
    <div v-if="shouldShowCTA"  class="cta-section">
      <div class="cta-content">
        <h3>{{ $t('cta.title') }}</h3>
        <p>{{ $t('cta.description') }}</p>
      </div>
      <div class="cta-button">
        <button class="cta-btn"  @click="goToTryNow">{{ $t('cta.buttonText') }}</button>
      </div>
    </div>

    <!-- Footer Section -->
    <div class="footer-section">
      <div class="footer-content">
        <div class="footer-column">
          <h4>{{ $t('footer.columns.products.title') }}</h4>
          <ul>
            <li v-for="(item, index) in $t('footer.columns.products.items')" :key="index">{{ item }}</li>
          </ul>
        </div>
        <div class="footer-column">
          <h4>{{ $t('footer.columns.solutions.title') }}</h4>
          <ul>
            <li v-for="(item, index) in $t('footer.columns.solutions.items')" :key="index">{{ item }}</li>
          </ul>
        </div>
        <div class="footer-column">
          <h4>{{ $t('footer.columns.pricing.title') }}</h4>
          <ul>
            <li v-for="(item, index) in $t('footer.columns.pricing.items')" :key="index">{{ item }}</li>
          </ul>
        </div>
        <div class="footer-column">
          <h4>{{ $t('footer.columns.languages.title') }}</h4>
          <ul>
            <li v-for="(item, index) in $t('footer.columns.languages.items')" :key="index">{{ item }}</li>
          </ul>
        </div>
        <div class="footer-column">
          <h4>{{ $t('footer.columns.company.title') }}</h4>
          <ul>
            <li v-for="(item, index) in $t('footer.columns.company.items')" :key="index">{{ item }}</li>
          </ul>
        </div>
      </div>
      <div class="footer-copyright">
        {{ $t('footer.copyright') }}
      </div>
    </div>
  </div>
</template>


<script>
export default {
  name: "Footer",
  computed: {
    shouldShowCTA() {
      // 根据当前路由控制CTA的显示
      const currentPath = this.$route.path;
      return currentPath !== '/joinnow' && currentPath !== '/company';
      // 在此处添加其他条件
    }
  },
  methods:{
     goToTryNow() {
      this.$router.push({ path: '/joinnow' }); // 替换为您的目标路径
    },
  }

}
</script>

<style scoped>
.cta-section {
  background-color: #0056e0;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px 20px;
  gap: 300px;
}

.cta-content {
  max-width: 900px;
  font-size: 18px;
}

.cta-content h3 {
  font-size: 20px;
  font-weight: bold;
  margin: 0;
}

.cta-content p {
  font-size: 18px;
  margin-top: 10px;
  text-align: left;
}

.cta-btn {
  width: 260px;
  height: 80px;
  background-color: #ffffff;
  color: #000;
  border: none;
  border-radius: 20px;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.cta-btn:hover {
  background-color: #f2f2f2;
}

.footer-section {
  background-color: #1a1a2e;
  height: 400px;
  color: #fff;
  padding: 40px 20px;
  position: relative; /* 允许子元素使用绝对定位 */
}

.footer-content {
  width: 1400px;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin: 0 auto 20px auto; /* 上下间距设置为 0 和 20px，左右设置为 auto */
}

.footer-copyright {
  position: absolute;
  bottom: 20px;
  left: 20px;
  font-size: 14px;
  color: #ccc;
  margin: 0px 0px 130px 320px;
}

.footer-column {
  text-align: left;
  margin: 0 10px;
}

.footer-column h4 {
  color: #0056e0;
  font-size: 20px;
  margin-bottom: 15px;
}

.footer-column ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.footer-column ul li {
  color: rgba(255, 255, 255, 0.6); /* 黑色，透明度60% */
  font-size: 18px;
  margin-bottom: 10px;
}
</style>