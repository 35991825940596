<template>
  <div class="form-container">
    <form class="custom-form">
      <label for="name">{{ $t('form.labels.name') }}</label>
      <input type="text" id="name" style="height: 35px" :placeholder="$t('form.placeholders.name')" />

      <label for="email">{{ $t('form.labels.email') }}</label>
      <input type="email" id="email" style="height: 35px" :placeholder="$t('form.placeholders.email')" />

      <label for="phone">{{ $t('form.labels.phone') }}</label>
      <input type="tel" id="phone" style="height: 35px" :placeholder="$t('form.placeholders.phone')" />

      <label for="message">{{ $t('form.labels.message') }}</label>
      <textarea id="message"  :placeholder="$t('form.placeholders.message')"></textarea>

      <button type="submit">{{ $t('form.submitButton') }}</button>
    </form>
  </div>
</template>


<script>
export default {
  name: "JoinNowForm",
};
</script>

<style scoped>

.form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000012; /* 背景颜色 */
}

.custom-form {
  width: 30%;
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin: 100px 0px;
}

label {
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 5px;
}

input[type="text"],
input[type="email"],
input[type="tel"],
textarea {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.2);
  color: #fff;
  font-size: 16px;
  margin-bottom: 30px;
}

textarea {
  resize: none;
  height: 150px;
  margin-bottom: 100px;
  font-family: "Microsoft YaHei", Arial, "Hiragino Kaku Gothic Pro", "Meiryo", sans-serif;
}

button {
  width: 103%;
  padding: 25px;
  background-color: #0056e0;
  color: #fff;
  border: none;
  border-radius: 10px;
  font-size: 20px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}
</style>
