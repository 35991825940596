<template>
  <nav class="navbar">
    <div class="navbar-inner">
      <div><img alt="Logo" src="../assets/logo/logo-top.png"></div>
      <ul :class="{ open: isNavOpen }" class="nav-links">
        <li><router-link to="/">{{ $t('navbar.home') }}</router-link></li>
        <li><router-link to="/description">{{ $t('navbar.description') }}</router-link></li>
        <li><router-link to="/solutions">{{ $t('navbar.solutions') }}</router-link></li>
        <li><router-link to="/pricing">{{ $t('navbar.pricing') }}</router-link></li>
        <li><router-link to="/company">{{ $t('navbar.company') }}</router-link></li>
      </ul>

      <!-- 语言切换下拉菜单 -->
      <div class="language-dropdown">
        <!-- 登录按钮 -->
        <button class="login-button" @click="goToLogin">{{ $t('navbar.login') }}</button>
        <button class="dropdown-toggle" @click="toggleDropdown">
          <img :src="currentLanguage.icon" alt="Current Language"/>
        </button>
        <ul v-if="isDropdownOpen" class="dropdown-menu">
          <li v-for="language in languages" :key="language.code" @click="setLanguage(language)">
            <img :alt="language.name" :src="language.icon"/>
            <span>{{ language.name }}</span>
          </li>
        </ul>
      </div>
      <div class="menu-icon" @click="toggleNav">☰</div>
    </div>
  </nav>
</template>

<script>
import Cookies from 'js-cookie';

export default {
  name: "Navbar",
  data() {
    return {
      isNavOpen: false,
      isDropdownOpen: false,
      languages: [
        {code: "zh", name: "简体中文", icon: require("../assets/icon/chinese.png")},
        {code: "ja", name: "日本語", icon: require("../assets/icon/japanese.png")},
        {code: "en", name: "English", icon: require("../assets/icon/english.png")},
      ],
      currentLanguage: {code: "zh", name: "简体中文", icon: require("../assets/language.png")}, // 默认语言
    };
  },
  methods: {
    goToLogin() {
      window.open("http://niumaai.work/ai") // 替换为您的目标路径
    },
    toggleNav() {
      this.isNavOpen = !this.isNavOpen;
    },
    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
    },
    setLanguage(language) {
      this.currentLanguage = language;
      this.$i18n.locale = language.code; // 切换语言
      this.isDropdownOpen = false; // 选择语言后关闭下拉菜单
    },
  },
  mounted() {
    // 点击页面其他地方时关闭下拉菜单
    document.addEventListener("click", this.handleOutsideClick);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.handleOutsideClick);
  },
  handleOutsideClick(event) {
    if (!this.$el.contains(event.target)) {
      this.isDropdownOpen = false;
    }
  },
};
</script>

<style scoped>
.navbar {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000012;
  height: 100px;
}

.navbar-inner {
  display: flex;
  align-items: center;
  gap: 100px;
  width: 100%;
  max-width: 1310px;
}

.menu-icon {
  display: none;
  font-size: 1.5em;
  cursor: pointer;
  color: #fff;
}

.nav-links {
  list-style: none;
  display: flex;
  gap: 80px;
  margin-left: auto;
}

.nav-links li a {
  color: #fff;
  text-decoration: none;
  font-size: 1.1em;
  position: relative;
  padding-bottom: 8px;
}

.nav-links li a::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -30px;
  width: 100%;
  height: 5px;
  background-color: #0d5eff;
  transform: scaleX(0);
  transition: transform 0.3s ease;
}

.nav-links li a:hover::after,
.nav-links li a.active::after {
  transform: scaleX(1);
}

.language-dropdown {
  width: 220px;
  position: relative;
  display: flex;
  align-items: center;
  margin-left: auto;
  padding-right: 20px;
}

.dropdown-toggle {
  background: none;
  border: none;
  color: #fff;
  font-size: 1em;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.dropdown-toggle img {
  width: 24px;
  height: 24px;
  margin-left: 14px;
  vertical-align: middle;
}

.dropdown-toggle span {
  vertical-align: middle;
}

.dropdown-menu {
  position: absolute;
  top: calc(100% + 5px);
  right: 0;
  background-color: #222;
  border: 1px solid #333;
  border-radius: 5px;
  width: 140px;
  list-style: none;
  padding: 10px 0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  z-index: 1000;
  text-align: left; /* 确保文本在菜单中左对齐 */
}

.dropdown-menu li {
  padding: 8px 15px;
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #fff;
}

.dropdown-menu li:hover {
  background-color: #333;
}

.dropdown-menu img {
  width: 20px;
  height: 20px;
  margin-right: 10px; /* 增加图片与文本之间的间距 */
}

.dropdown-menu span {
  display: inline-block;
  width: 80px; /* 统一宽度以确保对齐 */
  vertical-align: middle;
}

.login-button {
  width: 100px;
  height: 36px;
  background-color: #004be4;
  color: #fff;
  border: none;
  font-size: 18px;
  border-radius: 10px;
  cursor: pointer;
  margin-left: 20px;
  transition: background-color 0.3s ease;
}

.login-button:hover {
  background-color: #0a47cc;
}

@media (max-width: 768px) {
  .menu-icon {
    display: block;
  }

  .nav-links {
    position: absolute;
    top: 60px;
    right: 0;
    background-color: #111;
    flex-direction: column;
    align-items: center;
    width: 100%;
    display: none;
    gap: 20px;
    padding: 20px 0;
  }

  .nav-links.open {
    display: flex;
  }

  .nav-links li a {
    font-size: 1.2em;
  }
}
</style>
