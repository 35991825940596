import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import VueI18n from 'vue-i18n';

// 引入语言包
import zh from './locales/zh.json';
import ja from './locales/ja.json';
import en from './locales/en.json';

Vue.use(ElementUI);

Vue.config.productionTip = false;

Vue.use(VueI18n); // 注册 VueI18n 插件

const i18n = new VueI18n({
  locale: 'ja', // 设置默认语言
  fallbackLocale: 'ja', // 设置回退语言
  messages: {
    zh,
    ja,
    en,
  }
});

new Vue({
  router,
  render: (h) => h(App),
  i18n,
}).$mount("#app");
