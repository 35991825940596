<template>
  <div class="solution-section">
    <h2 class="section-title">{{ $t('solution.sectionTitle') }}</h2>
    <p class="section-subtitle">{{ $t('solution.sectionSubtitle') }}</p>
    <img :alt="$t('solution.backgroundImageAlt')" src="../assets/solution-background.png">
  </div>
</template>

<script>
export default {
  name: "Solutions"
}
</script>

<style scoped>
.solution-section {
  text-align: center;
  color: #fff;
  background-color: #0a0a1a;
  padding: 60px 20px;
  position: relative;
}

.section-title {
  font-size: 40px;
  font-weight: bold;
  margin-bottom: 40px;
}

.section-subtitle {
  font-size: 18px;
  color: #aaa;
  margin-top: 10px;
  margin-bottom: 80px;
}
</style>