<template>
  <div class="pricing-section">
    <h2>{{ $t('pricing.sectionTitle') }}</h2>
    <div class="pricing-options">
      <div class="pricing-card" v-for="(plan, index) in plans" :key="index">
        <div class="plan-name">{{ plan.name }}</div>
        <div class="price-subscribe">
          <p class="plan-price">{{ plan.price }}</p>
          <button class="subscribe-button" @click="goToTryNow">{{ $t('pricing.subscribeButton') }}</button>
        </div>
        <div class="plan-details">{{ plan.details }}</div>
      </div>
    </div>
    <div class="special-option">
      <div class="special-content">
        <h3 class="special-title">{{ specialPlan.name }}</h3>
        <p class="special-details">{{ specialPlan.details }}</p>
        <button class="subscribe-button" @click="goToTryNow">{{ $t('pricing.subscribeButton') }}</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Pricing",
  computed: {
    plans() {
      return [
        this.$t('pricing.plans.basic'),
        this.$t('pricing.plans.intermediate'),
        this.$t('pricing.plans.advanced')
      ];
    },
    specialPlan() {
      return this.$t('pricing.specialPlan');
    }
  },
  methods: {
    goToTryNow() {
      this.$router.push({ path: '/joinnow' }); // 替换为您的目标路径
    },
  }
};
</script>

<style scoped>


.pricing-section {
  text-align: center;
}

.pricing-section h2 {
  font-size: 34px;
  font-weight: bold;
  color: #000012;
  margin: 80px 0px;

}

.pricing-options {
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
}

.pricing-card {
  max-width: 100%;
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 20px;
  text-align: center;
  margin: 0px 20px;
}


.plan-details {
    font-size: 18px;
    color: #666;
    padding: 10px 0;
    text-align: left;

}

.plan-name {
  font-weight: bold;
  font-size: 24px;
  color: #000012;
  text-align: left;
  padding: 10px 0;
  border-bottom: 1px solid #ddd;

}

.price-subscribe {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
  border-bottom: 1px solid #ddd;
}

.plan-price {
  font-size: 18px;
  color: #666;
  margin-right: 30px;
}

.subscribe-button {
  background-color: #0d5eff;
  color: #fff;
  padding: 5px 20px;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  font-size: 18px;
  font-weight: bold;
  text-decoration: none;
}

.subscribe-button:hover {
  background-color: #0056b3;
}

/* Special option */
.special-option {
  width: 1030px;
  margin: 40px auto 80px;
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 0;
  text-align: left;
  background-color: #f8f8f8;
}

.special-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
}

.special-title {
  font-size: 24px;
  font-weight: bold;
  margin: 0;
  padding-right: 20px;
  border-right: 1px solid #ddd; /* 右边框 */
}

.special-details {
  font-size: 18px;
  color: #333;
  margin: 0 20px;
  flex: 1;
}
</style>
