<template>
  <div id="app">
    <Navbar />

    <router-view />

    <Footer />
  </div>
</template>

<script>
import Navbar from './components/Navbar.vue';
import Footer from './components/Footer.vue';

export default {
  components: {
    Navbar,
    Footer,
  }
}
</script>

<style>
/* 全局样式 */
#app {
  font-family: "Microsoft YaHei", Arial, "Hiragino Kaku Gothic Pro", "Meiryo", sans-serif;
  margin: 0;
  padding: 0;
}
</style>
