<template>
  <div class="about-section">
    <!-- Company History -->
    <div class="company-history">
      <h3>{{ $t('company.sectionTitles.history') }}</h3>
      <p>
        {{ $t('company.history.paragraph1') }}
      </p>
      <p style="margin-bottom: 100px">
        {{ $t('company.history.paragraph2') }}
      </p>
    </div>

    <div class="mission-vision">
      <!-- Mission Section -->
      <div class="mission">
        <div class="mission-content">
          <div class="text-content">
            <h3>{{ $t('company.sectionTitles.mission') }}</h3>
            <p>
              {{ $t('company.mission') }}
            </p>
          </div>
          <img alt="Company Mission" src="../assets/mission-image.png">
        </div>
      </div>

      <!-- Vision Section -->
      <div class="vision">
        <div class="vision-content">
          <img alt="Company Vision" src="../assets/vision-image.png">
          <div class="text-content">
            <h3 style="width: 100%; text-align: right">{{ $t('company.sectionTitles.vision') }}</h3>
            <p>
              {{ $t('company.vision') }}
            </p>
          </div>
        </div>
      </div>
    </div>

    <!-- Solution Section -->
    <div class="values-section">
      <h3>{{ $t('company.sectionTitles.values') }}</h3>
      <div class="values-content">
        <div class="value-item">
          <h4>{{ $t('company.values.customer_focus.title') }}</h4>
          <p>{{ $t('company.values.customer_focus.description') }}</p>
        </div>
        <div class="value-item">
          <h4>{{ $t('company.values.integrity_responsibility.title') }}</h4>
          <p>{{ $t('company.values.integrity_responsibility.description') }}</p>
        </div>
        <div class="value-item">
          <h4>{{ $t('company.values.excellence.title') }}</h4>
          <p>{{ $t('company.values.excellence.description') }}</p>
        </div>
        <div class="value-item">
          <h4>{{ $t('company.values.win_win.title') }}</h4>
          <p>{{ $t('company.values.win_win.description') }}</p>
        </div>
      </div>
    </div>

    <!-- Awards Section -->
    <div class="team-section">
      <h3>{{ $t('company.sectionTitles.team') }}</h3>
      <div class="carousel-container">
        <div class="carousel-content">
          <div
              v-for="(member, index) in teamMembers"
              :key="index"
              class="team-member-card"
          >
            <img :src="member.image" alt="Team Member" class="team-member-image"/>
            <h4>{{ member.name }}</h4>
            <p class="position">{{ member.position }}</p>
            <p class="description">{{ member.description }}</p>
          </div>
          <!-- Clone the content to create an infinite scrolling effect -->
          <div
              v-for="(member, index) in teamMembers"
              :key="'clone-' + index"
              class="team-member-card"
          >
            <img :src="member.image" alt="Team Member" class="team-member-image"/>
            <h4>{{ member.name }}</h4>
            <p class="position">{{ member.position }}</p>
            <p class="description">{{ member.description }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  name: 'Company',
  data() {
    return {
      scrollInterval: null,
    };
  },
  mounted() {
    if (this.$refs.carouselContent) {
      this.startScrolling();
    }
  },
  beforeDestroy() {
    clearInterval(this.scrollInterval);
  },
  computed: {
    teamMembers() {
      return [
        {
          name: this.$t('company.teamMembers[0].name'),
          position: this.$t('company.teamMembers[0].position'),
          image: require('@/assets/luwei.png'),
          description: this.$t('company.teamMembers[0].description'),
        },
        {
          name: this.$t('company.teamMembers[1].name'),
          position: this.$t('company.teamMembers[1].position'),
          image: require('@/assets/joe.png'),
          description: this.$t('company.teamMembers[1].description'),
        },
        {
          name: this.$t('company.teamMembers[2].name'),
          position: this.$t('company.teamMembers[2].position'),
          image: require('@/assets/Freya.png'),
          description: this.$t('company.teamMembers[2].description'),
        },
        {
          name: this.$t('company.teamMembers[3].name'),
          position: this.$t('company.teamMembers[3].position'),
          image: require('@/assets/fafa.png'),
          description: this.$t('company.teamMembers[3].description'),
        },
        {
          name: this.$t('company.teamMembers[4].name'),
          position: this.$t('company.teamMembers[4].position'),
          image: require('@/assets/xuanran.png'),
          description: this.$t('company.teamMembers[4].description'),
        }
      ];
    }
  },
  methods: {
    startScrolling() {
      const carouselContent = this.$refs.carouselContent;
      this.scrollInterval = setInterval(() => {
        carouselContent.scrollLeft += 1;
        if (carouselContent.scrollLeft >= carouselContent.scrollWidth - carouselContent.clientWidth) {
          carouselContent.scrollLeft = 0;
        }
      }, 20);
    },
  },
};
</script>

<style scoped>
.about-section {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.company-history {
  width: 100%;
  text-align: center;
  background-color: #f9f9f9;
}

.company-history h3 {
  font-size: 34px;
  font-weight: bold;
  color: #000012;
  margin: 140px 0px 90px 0px;
}

.company-history p {
  font-size: 18px;
  color: #333;
  line-height: 1.8;
  text-align: justify;
  margin: 0px auto;
  width: 60%;
}

/* Mission */
.mission-vision {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 140px auto 20px;
}

.mission,
.vision {
  width: 100%;
  text-align: center;
  margin-bottom: 160px;
}

.mission-content,
.vision-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.text-content {
  display: flex;
  flex-direction: column; /* 垂直排列标题和文本 */
  align-items: flex-start; /* 左对齐文本内容 */
  text-align: left;
  max-width: 400px; /* 设置文本内容的宽度 */
}

.text-content h3 {
  font-size: 34px;
  font-weight: bold;
  color: #004be4;
  margin: 0px 0px 100px 0px; /* 调整标题和文本间距 */
}

.mission-content p,
.vision-content p {
  font-size: 18px;
  color: #333;
  line-height: 1.6;
  max-width: 400px;
  text-align: justify;
  margin: 0px;
}

.mission-content img {
  width: 414px;
  height: 350px;
  margin: 0px 0px 0px 100px;
}

.vision-content img {
  width: 405px;
  height: 275px;
  margin: 0px 100px 0px 0px;
}


/* Solution */
.values-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 160px;
}

.values-section h3 {
  font-size: 34px;
  font-weight: bold;
  color: #000012;
  margin: 0px 0px 160px 0px;
}

.values-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 100%;
  max-width: 1200px;
}

.value-item {
  width: 100%;
  max-width: 200px;
  text-align: center;
  margin: 0px 35px;
}

.value-item h4 {
  font-size: 20px;
  font-weight: bold;
  color: #004be4;
  margin: 0px 0px 60px 0px;
}

.value-item p {
  font-size: 18px;
  color: #333;
  line-height: 1.6;
  margin: 0px;
}

/* Solution content with image on the left and text on the right */
.team-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f9f9f9;
  width: 100%;
}

.team-section h3 {
  font-size: 34px;
  font-weight: bold;
  color: #000012;
  text-align: center;
  margin: 100px 0px;
}

.carousel-container {
  overflow: hidden;
  width: 60%;
  display: flex;
  align-items: center;
}

.carousel-content {
  display: flex;
  animation: scroll 120s linear infinite;
}

/* Keyframes for the scrolling animation */
@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

.team-member-card {
  flex: 0 0 auto;
  width: 670px;
  background-color: #fff;
  text-align: center;
  padding: 60px;
  margin: 0px 40px 100px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.04); /* 添加阴影 */
}

.team-member-image {
  width: 273px;
  height: 240px;
  object-fit: cover;
}

.team-member-card h4 {
  font-size: 22px;
  color: #333;
}

.position {
  font-size: 20px;
  font-weight: bold;
  color: #333;
  margin: 40px;
}

.description {
  font-size: 18px;
  color: #333;
  line-height: 1.5;
  text-align: justify;
  margin: 0px;
  padding: 0px;
}
</style>
